import { Link, useSubmit } from "@remix-run/react";

export default function Footer({ user }: { user: any }) {
  const hasUser = user ? true : false;
  const today = new Date();

  const submit = useSubmit();

  return (
    <>
      <footer className="home_footer Customfoo-bg">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="footer-content">
                <div className="row align-items-center gy-5">
                  <div className="col-lg-3 col-md-12 col-sm-12 order01"></div>

                  <div className="col-lg-12 col-md-12 col-sm-12 order02">
                    <div className="footer_menu">
                      <ul>
                        <li>
                          <Link to="/terms-conditions">
                            Terms &amp; conditions
                          </Link>
                        </li>
                        <li>
                          <Link to="/privacy-policy"> Privacy statement</Link>
                        </li>
                        <li>
                          <Link to="/cookies">Cookies</Link>
                        </li>
                        <li>
                          <Link to="/pricing">Pricing</Link>
                        </li>
                        <li>
                          <Link to="/contact">Contact</Link>
                        </li>
                        <li>
                          <Link to="/about-us">About us</Link>{" "}
                        </li>
                        {hasUser && (
                          <li>
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                submit(null, {
                                  method: "post",
                                  action: "/get-support",
                                });
                              }}
                            >
                              Support
                            </a>
                          </li>
                        )}
                        {hasUser && (
                          <li>
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                submit(null, {
                                  method: "post",
                                  action: "/auth/logout",
                                });
                              }}
                            >
                              Logout
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`copyright-text copyright-new text-center`}
          style={{ width: "100%" }}
        >
          Flirtybum &copy; {today.getFullYear()}. All rights reserved.
        </div>
      </footer>
    </>
  );
}
